:root {
  --header-height: 48px;

  /*===== Colors =====*/
  --dark-gray: #24201A;
  --gray: #333333;
  --white: #fff;
  --light-blue: #1368CE;
  --blue: #0542B9;
  --yellow: #FFB800;
  --light-yellow: #FFC43B;
  --black: #24201A;
  --light-gray: #E6E6E6;

  --primary: var(--yellow);
  --primary-lighter: var(--light-yellow);
  --secondary: var(--blue);
  --text: var(--gray);
  --bg: var(--white);

  /*===== Font and typography =====*/
  --body-font: 'Montserrat', sans-serif;
  --title-font: 'Nunito';
  --big-font-size: 2.5rem;
  --normal-font-size: .938rem;
  --button-font: 'Montserrat', sans-serif;
  --button-font-size: 16px;
  --button-font-weight: 800;
  --button-font-line-height: 20px;

  /*===== z index =====*/
  --z-fixed: 100;
}

.btn-inline {
    padding: 15px;
    margin: 5px 0;

}

 .dash-btn {
    border: none;
    outline: none;
    width: 100%;
    letter-spacing: 1px;
    cursor: pointer;

    font-family:  var(--button-font);
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    line-height: var(--button-font-line-height);
  
    width: 256px;
    height: 56px;
    text-align: center;
  
    display: inline-block;
    background-color: var(--primary);
    color: var(--gray);
    padding: 15px 0;
    border-radius: 4px;
  
    box-shadow: inset 0px -4px 1px rgba(0, 0, 0, 0.6);
  }
  
  .login {
    width: 100%;
    min-height: 100vh;
    padding: 0 20px;
    background: --var(white);
    display: flex;
  }
  
  .login .loginContainer {
    padding: 60px;
    margin: auto;
    width: 100%;
    max-width: 520px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--white);
    border-width: 2px;
    border-color: var(--primary);
    border-style: solid;
  }
  
  .login .loginContainer label {
    color: var(--dark-gray);
    margin: 14px 0;
    display: block;
    font-size: 22px;
    line-height: 1;
  }
  
  .login .loginContainer input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 19px;
    padding: 10px;
    background: var(--light-gray);
    color: #fff;
    letter-spacing: 1px;
  }
  
  .login .loginContainer .btnContainer {
    width: 100%;
    padding: 24px 0;
  }
  
  .login .loginContainer .btnContainer p {
    margin: 14px 0 0 0;
    text-align: right;
    color: var(--dark-gray);
  }
  
  .login .loginContainer .btnContainer p span {
    color: yellow;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-left: 5px;
    cursor: pointer;
    transition: all 400ms ease-in-out;
  }
  
  .login .loginContainer .btnContainer p span:hover {
    color: var(--primary);
  }
  
  .login .loginContainer .errorMsg {
    color: red;
    font-size: 16px;
  }
  
  .logged {
    width: 100%;
    min-height: 100vh;
    background: var(--light-gray)
  }
  
  .logged nav {
    background: white;
    width: 100%;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .logged nav h2 {
    color: --var(--dark-gray);
  }
  
  .logged nav button {
    width: 86px;
  }
  