@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500;1,700;1,800&family=Nunito:ital,wght@0,400;0,800;0,900;1,400;1,800&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*===== VARIABLES CSS =====*/

:root {
    --header-height: 48px;

    /*===== Colors =====*/
    --dark-gray: #24201A;
    --gray: #333333;
    --white: #fff;
    --light-blue: #1368CE;
    --blue: #0542B9;
    --yellow: #FFB800;
    --light-yellow: #FFC43B;
    --black: #24201A;
    --light-gray: #E6E6E6;

    --primary: var(--yellow);
    --primary-lighter: var(--light-yellow);
    --secondary: var(--blue);
    --text: var(--gray);
    --bg: var(--white);

    /*===== Font and typography =====*/
    --body-font: 'Montserrat', sans-serif;
    --title-font: 'Nunito';
    --big-font-size: 2.5rem;
    --normal-font-size: .938rem;
    --button-font: 'Montserrat', sans-serif;
    --button-font-size: 16px;
    --button-font-weight: 800;
    --button-font-line-height: 20px;

    /*===== z index =====*/
    --z-fixed: 100;
}

/*===== BASE =====*/
*,::before,::after{
    box-sizing: border-box;
}

.portal-content {
    margin: 48px 0 0 0;
    margin: var(--header-height) 0 0 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-family: var(--body-font);
    font-size: .938rem;
    font-size: var(--normal-font-size);
    font-weight: 500;
    background-color: white;
    color: #333333;
    color: var(--text);
}

.portal-content h1 {
  font-family: 'Nunito', sans-serif;
}

.portal-content ul {
    padding: 0;
    list-style: none;
}

.portal-content a{ 
    text-decoration: none;
    color: inherit;
}
.portal-content a:visited{
  color: inherit;
}

.portal-content img {
    max-width: 100%;
    height: auto;
}

/*===== LAYOUT =====*/
.bd-grid{
  max-width: 1400px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  width: calc(100% - 6rem);
  margin-left: 3rem;
  margin-right: 3rem;
}

.l-header{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  z-index: var(--z-fixed);
  background-color: #fff;
  background-color: var(--white);
}

.l-main{
  width: 100%;
}

/*===== NAV =====*/
.nav{
  height: 48px;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__close{
  position: absolute;
  top: .75rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.nav__item{
  margin-bottom: 2rem;
  font-family: 'Montserrat', sans-serif;
  font-family: var(--body-font);
  font-weight: 700;
}

.nav__link{
  padding: 10px 14px;
}

.nav__logo img{
  max-width: 90%;
}

.nav__close, .nav__link, .nav__logo, .nav__toggle{
  color: #333333;
  color: var(--gray);
}

.nav__toggle{
  font-size: 1.5rem;
  cursor: pointer;
}

.nav-cta{
  font-family:  'Montserrat', sans-serif;
  font-family:  var(--button-font);
  text-align: center;
  /* display: inline-block; */
  background-color: #FFB800;
  background-color: var(--primary);
  color: #333333;
  color: var(--gray);
  border-radius: 4px;

  box-shadow: inset 0px -3px 1px rgba(0, 0, 0, 0.6);
}


/* ********* COMMON CONFIGS FOR DESKTOP AND MOBILE */
.primary-button{
  font-family:  'Montserrat', sans-serif;
  font-family:  var(--button-font);
  font-size: 16px;
  font-size: var(--button-font-size);
  font-weight: 800;
  font-weight: var(--button-font-weight);
  line-height: 20px;
  line-height: var(--button-font-line-height);

  width: 256px;
  height: 56px;
  text-align: center;

  display: inline-block;
  background-color: #FFB800;
  background-color: var(--primary);
  color: #333333;
  color: var(--gray);
  padding: 1.125rem 2rem;
  border-radius: 4px;

  box-shadow: inset 0px -4px 1px rgba(0, 0, 0, 0.6);
}

.secondary-button{
  font-family:  'Montserrat', sans-serif;
  font-family:  var(--button-font);
  font-size: 16px;
  font-size: var(--button-font-size);
  font-weight: 800;
  font-weight: var(--button-font-weight);
  line-height: 20px;
  line-height: var(--button-font-line-height);

  width: 256px;
  height: 56px;
  text-align: center;

  display: inline-block;
  background-color: #0542B9;
  background-color: var(--secondary);
  color: #fff;
  color: var(--white);
  padding: 1.125rem 2rem;
  border-radius: 4px;

  box-shadow: inset 0px -4px 1px rgba(0, 0, 0, 0.6);
}

.hero__description, .hero__disclaimer {
  font-weight: 400;
}

.how {
  background-color: #FFB800;
  background-color: var(--primary);
}

.how h1, .how h2, .how p, .how.a {
  color: #24201A;
  color: var(--dark-gray);
}

.what {
  background-color: #24201A;
  background-color: var(--dark-gray);
}

.what h1, .what h2, .what p {
  color: #fff;
  color: var(--white);
}

.why {
  background-color: #fff;
  background-color: var(--white);
}

.why-title {
  color: #24201A;
  color: var(--dark-gray);
}

.contact {
  background-color: #E6E6E6;
  background-color: var(--light-gray);
}

.contact h1, .contact h2, .contact p{
  color: #24201A;
  color: var(--dark-gray);;
}

.sub-title {
  text-align: center;
}

.footer {
  background-color: black;
  color: #fff;
  color: var(--white);
}

.logo__wrapper {
  text-align: left;
}


.what .dot{
  background-color: #FFB800;
  background-color: var(--primary);
  color: #24201A;
  color: var(--dark-gray);
}

.what .dot img {
  width: 65%;
}

.dot {
  height: 55px;
  width: 55px;
  background-color: #24201A;
  background-color: var(--dark-gray);
  border-radius: 50%;
  /* display: inline-block; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  color: var(--white);
  text-align: center;
  font-size: 32px;
}
.what .heart img {
  width: 45%;
  margin-top: 2px;
}

.what .community img {
  width: 55%;
}


.card-container p {
  font-family: 'Montserrat', sans-serif;
  font-family: var(--body-font);
  font-weight: 500;
}

.inline-link {
  text-decoration: underline !important;
  font-weight: bold;
}

.how-text {
  margin-top: 40px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* ===== MEDIA QUERIES =====*/
@media screen and (min-width: 769px){
  .portal-content{
    margin: calc(48px + 1.5rem) 0 0 0;
    margin: calc(var(--header-height) + 1.5rem) 0 0 0;
  }
  
  .nav{
    height: calc(48px + 1.5rem);
    height: calc(var(--header-height) + 1.5rem);
    background-color: #fff;
    background-color: var(--white);
  }

  .nav__toggle, .nav__close{
    display: none;
  }

  .nav__list{
    display: flex;
  }

  .nav__item{
    margin-left: 3rem;
    margin-bottom: 0;
  }

  /* ******* HERO SECTION--- > 768PX (DESKTOP) */
  .hero__container{
    padding-top: 100px;
    padding-bottom: 150px;

  }

  .hero__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
  }

  .hero__data{
    max-width: 625px;
    width: 60%;
  }

  .hero__title{
    margin-bottom: 10px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 52px;
    line-height: 68px;
    display: flex;
    align-items: flex-end;
    color: #24201A;
    color: var(--black);

    margin-bottom: 25px;
  }
  
  .hero__button{
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .hero__description {
    font-size: 22px;
    line-height: 30px;
    padding-right: 25px;
    margin-bottom: 25px;
  }
  
  .hero__disclaimer {
    font-size: 18px;
  }

  .hero__image {
    width: 40%;
  }

  .hero__image img {
    width: 100%;
  }

  .section-title {
    text-align: center;
    font-size: 42px;
    font-weight: 800;
    padding-bottom: 30px;
  }

   /* WHAT SECTION - DESKTOP  */
  .what-container {
    padding-top: 68px;
    padding-bottom: 68px;
  }

  .card-wrapper{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px,1fr));
    grid-column-gap: 60px;
    -webkit-column-gap: 60px;
            column-gap: 60px;
    align-items: start;
    justify-self: center;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
  }
  
  .how .card-wrapper {
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .how-text {
    margin-top: 60px;
  }
  

  .what-title {
    line-height: 68px;
    margin-bottom: 20px;
    text-align: center;
  }

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-family: var(--body-font);
    font-size: 22px;
  }

  .card-container {
    width: 100%;
    padding: 10px;
  }

  .card-container p {
    font-size: 16px;
    line-height: 26px;
  }

  .what-cta-container{
    margin-top: 45px;
    margin-bottom: 30px;
    text-align: center;
    /* color: var(--white); */
  }

  /* MUTUAL SECTIONS */
  .container {
    padding-top: 68px;
    padding-bottom: 68px;
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
/* WHY SECTION - DESKTOP */
  .why-image {
    width: 40%;
    max-width: 420px;
    padding-left: 20px;

  }
  .why-title {
    margin-bottom: 40px;
  }
  .why-content{
    width: 60%;
    max-width: 670px;
    font-size: 24px;
    line-height: 36px;
    padding-left: 25px;
    text-align: left;
  }
  .btn{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .contact-button{
    margin-top: 50px;
  }
  .sub-title {
    font-size: 16px;
  }
  .footer-container{
    min-height: 300px;
  }
  .footer-links-wrapper{
    width: 70%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px,1fr));
    grid-auto-flow: column;
    direction: rtl;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    justify-content: end;
    padding-left: 30px;
    padding-right: 30px;
  }
  .footer-links-card{
    padding: 10px;
    width: 100%;
    line-height: 36px;
    font-size: 16px;
    text-align: right;
  }
  .footer-wrapper{

  }
  .company-info{
    width: 30%;
    text-align: left;
  }
  .social-network_title{
    width: 100%;
  }
  .footer-links-title{
    font-size:20px;
  }
  .social-network {
    padding-top: 35px;
    line-height: 30px;
    font-size: 14px;
    font-family: Nunito, sans-serif;
    text-align: left;
  }
  .social-logo {
    display: inline-block;
    margin-right: 10px;
    width: 30px;
  }
  .copyright {
    margin-top: 50px;
  }

  .page-not-found .card-container h2 {
    line-height: 30px;
    font-weight: 500;
  }
  .page-not-found .card-wrapper {
    align-items: center;
    padding: 60px 0;
  }

}



/* ********* MOBILE*********
******
 MEDIA MOBILE OR LESS THAN 768PX
 ***** 
 */
@media screen and (max-width: 769px){

  .nav__menu{
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100%;
    padding: 3.5rem 1.5rem 0;
    background: rgba(255,255,255,.3);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    transition: .5s;
    -webkit-transition: .5s;
  }
  .nav__logo img{
    max-width: 55%;
  }
  .bd-grid {
    width: calc(100% - 38px);
    margin-left: 19px;
    margin-right: 19px;
  }
  .hero{
    background-color: #fff;
    background-color: var(--white);
  }

  /* HERO SECTION */
  .hero__container{
    padding-top: 5px;
    padding-bottom: 45px;

  }
  
  .hero__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
  }
  .primary-button{
      margin-top: 20px;
      margin-bottom: 20px;
  }
  
  .hero__description {
    margin-top: 25px;
    font-size: 14px;
  }
  
  .hero__disclaimer {
    font-size: 12px;
  }

  .hero__title{
    margin-bottom: 10px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    display: flex;
    align-items: flex-end;
    color: #24201A;
    color: var(--black);

    margin-top: 30px;
  }

  .hero__wrapper{
      flex-direction: column;
      justify-content: left;
  }
  .hero__image {
    order: 1;
    width: 80%;
    max-width: 350px;
  }
  .hero__data {
    order: 2;
    width: 100%;
  }

  .section-title {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 30px;
    font-weight: 800;
    margin-top: 0;
    margin-bottom: 30px;
  }

  /* HOW SECTION - MOBILE */
  .dot {
    height: 45px;
    width: 45px;
    font-size: 24px;
  }
  .how-text {
    font-size: 18px;
  }

  /* WHAT SECTION - MOBILE  */
  .what-container {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .what-title {
    line-height: auto;
  }

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-family: var(--body-font);
    font-size: 22px;
  }
  .card-wrapper{
    text-align: left;
    width: 95%;
  }

  .card-container {
    /* max-width: 280px; */
    padding: 10px;
    width: 100%;
  }

  .card-container p {
    font-size: 16px;
    line-height: 26px;
  }
  .what-cta-container{
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
  }
  .container{
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .wrapper {
    width: 100%;
    flex-direction: column;
    justify-content: left;
    text-align: left;
  }
/* WHY SECTION - MOBILE */
  .why-image {
    order: 1;
    width: 100%;
    max-width: 220px;
    text-align: center;
  }
  .why-title {
    margin-bottom: 40px;
  }
  .why-content{
    order: 2;
    width: 100%;
    max-width: 670px;
    font-size: 20px;
    line-height: 36px;
    padding-top: 20px;
  }

  /* CONTACAT SECTION - MOBILE */
  .sub-title {
    max-width: 280px;
    text-align: left;
    margin-bottom: 25px;
  }

  /* FOOTER SECTION -MOBILE */
  .footer-logo{
    width: 50%;
  }
  .footer-logo img {
    width: 100%;
  }
  .footer-links-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    margin-bottom: 10px;
  }
  .footer-links-card{
    width: 100%;
    line-height: 36px;
    font-size: 16px;
    margin-bottom: 15px;
  }
  .footer-links-title{
    font-size:20px;
  }
  .footer-wrapper{

  }
  .company-info{
    width: 100%;
    margin-bottom: 30px;
  }
  .social-network_title{
    width: 100%;
    padding-bottom: 15px;
  }
  .social-network {
    padding-top: 35px;
    line-height: 24px;
    font-size: 16px;
    font-family: Nunito, sans-serif;
    text-align: left;
  }
  .social-logo {
    display: inline-block;
    margin-right: 10px;
    width: 30px;
  }
  .copyright{
    text-align: left;
    margin-top: 25px;
  }
  .page-not-found .card-container {
    padding: 10px 0;
    margin-bottom: 30px;
  }

  .page-not-found .card-container img {
    max-width: 500px;
    width: 80%;
    margin-left: 20px;
  }

  .page-not-found .card-container h2 {
    line-height: 30px;
    font-weight: 400;
    font-size: 18px;
  }
  .page-not-found .card-wrapper {
    align-items: center;
    padding-top: 30px;

  }

}

  /*=== Show menu ===*/
  .show{
    right: 0;
  }

.copyright{
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}


@media screen and (min-width: 1024px){
  .bd-grid{
    margin-left: auto;
    margin-right: auto;
  }
}
#policies li {
    justify-content: left;
    text-align: left;
}

#policies p {
    line-height: 1.38;
    text-align: justify;
    background-color: rgb(255, 255, 255);
    margin-top: 0pt;
    margin-bottom: 0pt;
    padding: 0pt 0pt 12pt;
    font-size: 12pt;
    font-family: Montserrat, sans-serif;
    background-color: transparent;
    font-weight: 400;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    white-space: pre-wrap;
}

#policies h2 {
    font-size: 15pt;
    font-family: Montserrat, sans-serif;
    background-color: transparent;
    font-weight: 700;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    white-space: pre-wrap;
    text-align: left;
}

#policies h3 {
    line-height: 1.44;
    text-align: justify;
    background-color: rgb(255, 255, 255);
    margin-top: 0pt;
    margin-bottom: 0pt;
    padding: 0pt 0pt 15pt;
}
:root {
  --header-height: 48px;

  /*===== Colors =====*/
  --dark-gray: #24201A;
  --gray: #333333;
  --white: #fff;
  --light-blue: #1368CE;
  --blue: #0542B9;
  --yellow: #FFB800;
  --light-yellow: #FFC43B;
  --black: #24201A;
  --light-gray: #E6E6E6;

  --primary: var(--yellow);
  --primary-lighter: var(--light-yellow);
  --secondary: var(--blue);
  --text: var(--gray);
  --bg: var(--white);

  /*===== Font and typography =====*/
  --body-font: 'Montserrat', sans-serif;
  --title-font: 'Nunito';
  --big-font-size: 2.5rem;
  --normal-font-size: .938rem;
  --button-font: 'Montserrat', sans-serif;
  --button-font-size: 16px;
  --button-font-weight: 800;
  --button-font-line-height: 20px;

  /*===== z index =====*/
  --z-fixed: 100;
}

.btn-inline {
    padding: 15px;
    margin: 5px 0;

}

 .dash-btn {
    border: none;
    outline: none;
    width: 100%;
    letter-spacing: 1px;
    cursor: pointer;

    font-family:  'Montserrat', sans-serif;

    font-family:  var(--button-font);
    font-size: 16px;
    font-size: var(--button-font-size);
    font-weight: 800;
    font-weight: var(--button-font-weight);
    line-height: 20px;
    line-height: var(--button-font-line-height);
  
    width: 256px;
    height: 56px;
    text-align: center;
  
    display: inline-block;
    background-color: #FFB800;
    background-color: var(--primary);
    color: #333333;
    color: var(--gray);
    padding: 15px 0;
    border-radius: 4px;
  
    box-shadow: inset 0px -4px 1px rgba(0, 0, 0, 0.6);
  }
  
  .login {
    width: 100%;
    min-height: 100vh;
    padding: 0 20px;
    background: --var(white);
    display: flex;
  }
  
  .login .loginContainer {
    padding: 60px;
    margin: auto;
    width: 100%;
    max-width: 520px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    background: var(--white);
    border-width: 2px;
    border-color: #FFB800;
    border-color: var(--primary);
    border-style: solid;
  }
  
  .login .loginContainer label {
    color: #24201A;
    color: var(--dark-gray);
    margin: 14px 0;
    display: block;
    font-size: 22px;
    line-height: 1;
  }
  
  .login .loginContainer input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 19px;
    padding: 10px;
    background: #E6E6E6;
    background: var(--light-gray);
    color: #fff;
    letter-spacing: 1px;
  }
  
  .login .loginContainer .btnContainer {
    width: 100%;
    padding: 24px 0;
  }
  
  .login .loginContainer .btnContainer p {
    margin: 14px 0 0 0;
    text-align: right;
    color: #24201A;
    color: var(--dark-gray);
  }
  
  .login .loginContainer .btnContainer p span {
    color: yellow;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-left: 5px;
    cursor: pointer;
    transition: all 400ms ease-in-out;
  }
  
  .login .loginContainer .btnContainer p span:hover {
    color: #FFB800;
    color: var(--primary);
  }
  
  .login .loginContainer .errorMsg {
    color: red;
    font-size: 16px;
  }
  
  .logged {
    width: 100%;
    min-height: 100vh;
    background: #E6E6E6;
    background: var(--light-gray)
  }
  
  .logged nav {
    background: white;
    width: 100%;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .logged nav h2 {
    color: --var(--dark-gray);
  }
  
  .logged nav button {
    width: 86px;
  }
  
