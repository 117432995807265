#policies li {
    justify-content: left;
    text-align: left;
}

#policies p {
    line-height: 1.38;
    text-align: justify;
    background-color: rgb(255, 255, 255);
    margin-top: 0pt;
    margin-bottom: 0pt;
    padding: 0pt 0pt 12pt;
    font-size: 12pt;
    font-family: Montserrat, sans-serif;
    background-color: transparent;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    white-space: pre-wrap;
}

#policies h2 {
    font-size: 15pt;
    font-family: Montserrat, sans-serif;
    background-color: transparent;
    font-weight: 700;
    font-style: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    white-space: pre-wrap;
    text-align: left;
}

#policies h3 {
    line-height: 1.44;
    text-align: justify;
    background-color: rgb(255, 255, 255);
    margin-top: 0pt;
    margin-bottom: 0pt;
    padding: 0pt 0pt 15pt;
}